body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.OTSubscriberContainer,
.OT_subscriber {
  padding: 5;
  margin-left: auto !important;
  margin-right: auto !important;
  border-width: 5;
  border-color: gray;
  margin-top: 15px;
  padding-top: 15px;
  width: 50%;
  display: inline-block;
}

.OTPublisherContainer,
.OT_publisher {
  margin-top: 15px;
  padding-top: 15px;
  margin-left: auto !important;
  margin-right: auto !important;

  border-color: green;
  width: 50%;
  display: inline-block;
}

.logon-card {
  border-width: 1px;
  border-style: solid;
  border-color: lightgray;
}

.card-title-color {
  background-color: rgba(0, 0, 0, 0.03);
}
/* #sessionInfoDiv {
  display: none;
} */
