body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.OTSubscriberContainer,
.OT_subscriber {
  padding: 5;
  margin-left: auto !important;
  margin-right: auto !important;
  border-width: 5;
  border-color: gray;
  margin-top: 15px;
  padding-top: 15px;
  width: 50%;
  display: inline-block;
}

.OTPublisherContainer,
.OT_publisher {
  margin-top: 15px;
  padding-top: 15px;
  margin-left: auto !important;
  margin-right: auto !important;

  border-color: green;
  width: 50%;
  display: inline-block;
}

.logon-card {
  border-width: 1px;
  border-style: solid;
  border-color: lightgray;
}

.card-title-color {
  background-color: rgba(0, 0, 0, 0.03);
}
/* #sessionInfoDiv {
  display: none;
} */

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.page-heading {
  font-size: 1.1em !important;
  padding-top: 25px !important;
}

#videoContainerDiv {
  width: 100%;
}
.video-col {
  margin: 3px !important;
  padding: 3px !important;
  border-width: 1px;
  border-color: lightgray;
  border-style: ridge none ridge none;
}
.video-card {
  min-height: 220px;
}

.chat-element {
  font-size: 0.8em;
}

.api-info {
  display: block;
}
.kaigie-btn {
  font-size: 0.75em !important;
  width: 150px !important;
}

.panel-background {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#a7c7dc+0,85b2d3+100;Blue+Grey+3D */
  background: rgb(167, 199, 220); /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(167, 199, 220, 1) 0%,
    rgba(133, 178, 211, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a7c7dc', endColorstr='#85b2d3',GradientType=0 ); /* IE6-9 */
}

.panel-background-2 {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#93cede+0,75bdd1+41,49a5bf+100;Blue+3D+%235 */
  background: rgb(147, 206, 222); /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(147, 206, 222, 1) 0%,
    rgba(117, 189, 209, 1) 41%,
    rgba(73, 165, 191, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#93cede', endColorstr='#49a5bf',GradientType=0 ); /* IE6-9 */
}
.panel-background-3 {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f2f5f6+0,e3eaed+37,c8d7dc+100;Grey+3D+%234 */
  background: rgb(242, 245, 246); /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(242, 245, 246, 1) 0%,
    rgba(227, 234, 237, 1) 37%,
    rgba(200, 215, 220, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f2f5f6', endColorstr='#c8d7dc',GradientType=0 ); /* IE6-9 */
}

.panel-background-4 {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#d4e4ef+0,86aecc+100;Grey+Gloss */
  background: rgb(212, 228, 239); /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(212, 228, 239, 1) 0%,
    rgba(134, 174, 204, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d4e4ef', endColorstr='#86aecc',GradientType=0 ); /* IE6-9 */
}

.panel-background-5 {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e2e2e2+0,dbdbdb+50,d1d1d1+51,fefefe+100;Grey+Gloss+%231 */
  background: rgb(226, 226, 226); /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(226, 226, 226, 1) 0%,
    rgba(219, 219, 219, 1) 50%,
    rgba(209, 209, 209, 1) 51%,
    rgba(254, 254, 254, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2e2e2', endColorstr='#fefefe',GradientType=0 ); /* IE6-9 */
}
/* next good for appointments */
.panel-background-6 {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#88bfe8+0,70b0e0+100;Blue+3D+%2317 */
  background: rgb(136, 191, 232); /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(136, 191, 232, 1) 0%,
    rgba(112, 176, 224, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#88bfe8', endColorstr='#70b0e0',GradientType=0 ); /* IE6-9 */
}

.panel-background-7 {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f2f5f6+0,e3eaed+37,c8d7dc+100;Grey+3D+%234 */
  background: rgb(242, 245, 246); /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(242, 245, 246, 1) 0%,
    rgba(227, 234, 237, 1) 37%,
    rgba(200, 215, 220, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f2f5f6', endColorstr='#c8d7dc',GradientType=0 ); /* IE6-9 */
}

.panel-background-8 {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#fefcea+0,f1da36+100 */
  background: rgb(254, 252, 234); /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(254, 252, 234, 1) 0%,
    rgba(241, 218, 54, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fefcea', endColorstr='#f1da36',GradientType=0 ); /* IE6-9 */
}

.panel-background-9 {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,f3f3f3+50,ededed+51,ffffff+100;White+Gloss+%232 */
  background: rgb(255, 255, 255); /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(243, 243, 243, 1) 50%,
    rgba(237, 237, 237, 1) 51%,
    rgba(255, 255, 255, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
}

.page-heading {
  font-size: 1.2em !important;
  padding-top: 25px !important;
}

.mobile-heading {
  font-size: 1em !important;
  padding-top: 25px !important;
}

#root {
  width: 99%;
  margin-left: 0.5%;
  margin-right: 0.5%;
}

.video-row {
  margin-right: 0;
  margin-left: 0;
}

.heading-wrapper {
  display: none;
}

.mobile-wrapper {
  display: block;
}
@media only screen and (min-device-width: 768px) {
  #root {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  /* Styles */
  .video-row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
    margin-right: -15px;
    margin-left: -15px;
  }

  .heading-wrapper {
    display: block;
  }
  .mobile-wrapper {
    display: none;
  }
  .chat-element {
    font-size: 1em;
  }

  .api-info {
    display: block;
    font-size: 0.75em;
  }
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

